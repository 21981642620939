import { Typography } from '@material-ui/core';
import { Trip, TripId, TripStatus, TripVersion } from '@tripr/common';
import React, { useState } from 'react';
import { AccountApi, HostApi, TripWithVersionApi } from '../../api/RpcClient';
import { useToast } from '../../utils/Utils';
import { LoadingGuard } from '../common/LoadingGuard';
import { TripForm, TripSchema, TripValues } from './components/TripForm';
import { useAuthContext } from '../../utils/AuthContext';

export const TripEditPage: React.FC<{ tripId: TripId }> = props => {
  const [callWithToast] = useToast('trip');
  // using this stat to pass updated slug to TripForm so it can be used for 'Show preview' button in Edit page
  const [slug, setSlug] = useState('');
  const [trip, setTrip] = useState({});
  const authContext = useAuthContext();

  const accountId = authContext.isLoggedIn ? authContext.account.accountId : null;

  return (
    <LoadingGuard
      loadData={async () => {
        const hosts = await HostApi.getHostOptions();
        const trip = await TripWithVersionApi.getWithVersion(TripId(props.tripId));
        const tripOptions = await TripWithVersionApi.getRecommendedTripOptions();
        // get articles categories from contentful
        const articleCategories = await TripWithVersionApi.getContentfulCategories();
        setSlug(trip.currentVersion.slug);
        setTrip(trip);
        return { hosts, trip, tripOptions, articleCategories };
      }}
    >
      {({ hosts, trip, tripOptions, articleCategories }) => (
        <div>
          <div style={{ display: 'flex' }}>
            <Typography gutterBottom variant="h3">
              {trip.currentVersion.title}
            </Typography>
          </div>
          <TripForm
            slug={slug}
            trip={{
              ...trip.currentVersion,
              status: trip.status,
              active: trip.active,
              featured: trip.featured,
              hidden: trip.hidden,
              featuredPosition: trip.featuredPosition,
              deleted: trip.deleted,
              hostProfileId: trip.hostProfileId,
              createdById: trip.createdById,
              tripInclusions: trip.currentVersion.tripInclusions,
              uploaderEmail: trip.uploader?.email || null,
            }}
            hosts={hosts}
            tripOptions={tripOptions}
            articleCategories={articleCategories}
            onSubmit={async (values: TripValues) => {
              try {
                const { hostProfileId, createdById, status, active, featured, deleted, featuredPosition, hidden, uploaderEmail, ...tripVersionValues } =
                  TripSchema.cast(values);
                const { currentVersion, uploader, ...tripFields } = trip;
                const newTrip: Trip = {
                  ...tripFields,
                  status: status as TripStatus,
                  active,
                  deleted,
                  featured,
                  hidden,
                  featuredPosition,
                  hostProfileId,
                  createdById,
                };

                if (authContext.isLoggedIn) {
                  newTrip.uploaderId = authContext.account.accountId;
                }

                const tripVersion: TripVersion = {
                  ...tripVersionValues,
                  tripId: trip.tripId,
                  tripVersionId: trip.currentVersion.tripVersionId,
                };
                // making first image of the gallery as cover image
                if (values && tripVersionValues?.gallery.length > 0) {
                  tripVersion.coverImage = tripVersionValues.gallery[0];
                }
                await callWithToast(TripWithVersionApi.updateTripWithVersion(newTrip, tripVersion));
                setSlug(tripVersion.slug);
                setTrip(tripVersion);
              } catch (e) {
                console.log(e);
              }
            }}
          />
        </div>
      )}
    </LoadingGuard>
  );
};
