import React from 'react';
import MaterialTable from 'material-table';
import { LoadingGuard } from '../common/LoadingGuard';
import { PaymentApi } from '../../api/PaymentsApi';
import { Button, CircularProgress } from '@material-ui/core';
import { PaymentStatus, PaymentWithReservation } from '@tripr/common';
import { format, parseISO } from 'date-fns';
import InstallmentStatusRow from './components/InstallmentStatusRow';
import { useHistory, Redirect } from 'react-router-dom';
import { useAuthContext } from '../../utils/AuthContext';

const STRIPE_PAYMENT_URL = process.env.NODE_ENV == 'development' ? 'https://dashboard.stripe.com/test/payments/' : 'https://dashboard.stripe.com/payments/';

const STRIPE_INVOICE_URL = process.env.NODE_ENV == 'development' ? 'https://dashboard.stripe.com/test/invoices/' : 'https://dashboard.stripe.com/invoices/';

const sortPayments = (payments: PaymentWithReservation[]): PaymentWithReservation[] => {
  return payments.sort((a, b) => {
    // Sort by status, with 'pending' first
    if (a.status === PaymentStatus.Pending && b.status !== PaymentStatus.Pending) {
      return -1;
    }
    if (a.status !== PaymentStatus.Pending && b.status === PaymentStatus.Pending) {
      return 1;
    }

    // If statuses are the same or neither is 'pending', sort by due date
    const dateA = new Date(a.dueDate);
    const dateB = new Date(b.dueDate);
    return dateA.getTime() - dateB.getTime();
  });
};

const Loader = ({ showModal }: { showModal: boolean }) => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: showModal ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      zIndex: 100000,
    }}
  >
    <CircularProgress />
  </div>
);

export const InstallmentsListPage: React.FC = () => {
  const authContext = useAuthContext();
  // if not superadmin redirecting to /trips page
  if (authContext.isLoggedIn && authContext.account.accountType != 'superadmin') return <Redirect to="/trips" />;

  const history = useHistory();

  const getSelectedIds = (selected: PaymentWithReservation | PaymentWithReservation[]) => {
    let selectedIds: Array<string> = []; // contains paymentIds of selected installments
    if (Array.isArray(selected)) {
      // if multiple payments are selected
      selectedIds = selected.map(el => el.paymentId);
    } else {
      selectedIds.push(selected.paymentId);
    }
    return selectedIds;
  };

  const [showModal, setShowModal] = React.useState(false);

  const handleChargeSelectedClick = async (selected: PaymentWithReservation | PaymentWithReservation[]) => {
    setShowModal(true); // Show loader
    const selectedIds = getSelectedIds(selected);
    try {
      if (selectedIds?.length) {
        const response = await PaymentApi.chargePayments(selectedIds);
        if (response && response.result) {
          history.go(0); // Reload the page after successful payment charge
        }
      }
    } catch (error) {
      console.error('Error charging payments:', error);
    } finally {
      setShowModal(false); // Hide loader
    }
  };

  const handleCancelSelectedClick = async (selected: PaymentWithReservation | PaymentWithReservation[]) => {
    setShowModal(true); // Show loader
    const selectedIds = getSelectedIds(selected);
    try {
      if (selectedIds?.length) {
        // call cancelPayments API with selected paymentIds
        const response = await PaymentApi.cancelPayments(selectedIds);
        if (response && response.statusCode === 200) {
          history.go(0); // Reload the page after successful payment cancellation
        }
      }
    } catch (error) {
      console.error('Error canceling payments:', error);
    } finally {
      setShowModal(false); // Hide loader
    }
  };

  return (
    <>
      <LoadingGuard
        loadData={async () => {
          return {
            payments: (await PaymentApi.getPayments()) || [],
          };
        }}
      >
        {({ payments }) => (
          <>
            <MaterialTable
              data={sortPayments(payments || [])}
              title={'Installments'}
              columns={[
                { title: 'Reservation ID', field: 'reservationId' },
                { title: 'Customer', field: 'reservation.stripeResponse.customer.name' },
                /**TODO: pull currency symbol from row.currency instead of hard-coding */
                { title: 'Amount', render: row => <p>${row.amount}</p> },
                {
                  title: 'Status',
                  field: 'status',
                  render: row => <InstallmentStatusRow status={row.status} dueDate={row.dueDate} />,
                },
                { title: 'Installment Date', type: 'date', field: 'dueDate', render: d => format(parseISO(d.dueDate), 'd MMM yyyy') },
                {
                  title: 'Stripe Payment',
                  render: row =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    row.status === PaymentStatus.Paid && row.stripeResponse?.payment ? (
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      <a target="_blank" href={`${STRIPE_PAYMENT_URL}${row.stripeResponse.payment.id}`}>
                        {/** // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore*/}
                        {row.stripeResponse.payment.id}
                      </a>
                    ) : (
                      ''
                    ),
                },
                { title: 'Remarks', field: 'remarks' },
                // {
                //   title: 'Stripe invoice',
                //   render: row =>
                //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //     // @ts-ignore
                //     row.stripeResponse?.invoice ? (
                //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //       // @ts-ignore
                //       <a target="_blank" href={`${STRIPE_INVOICE_URL}${row.stripeResponse.invoice.id}`}>
                //         {/** // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //       // @ts-ignore*/}
                //         {row.stripeResponse.invoice.id}
                //       </a>
                //     ) : (
                //       ''
                //     ),
                // },
              ]}
              options={{
                sorting: true,
                selection: true,
                selectionProps: (rowData: PaymentWithReservation) => ({
                  disabled: rowData.status !== PaymentStatus.Pending,
                }),
                pageSize: 20,
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  tooltip: 'Charge selected payments',
                  icon: () => (
                    <Button color="primary" variant="contained" size="small">
                      Charge selected
                    </Button>
                  ),
                  onClick: (evt, data) => handleChargeSelectedClick(data),
                },
                {
                  tooltip: 'Cancel selected payments',
                  icon: () => (
                    <Button color="primary" variant="text" size="small" style={{ color: 'red' }}>
                      Cancel selected
                    </Button>
                  ),
                  onClick: (evt, data) => handleCancelSelectedClick(data),
                },
              ]}
            ></MaterialTable>
          </>
        )}
      </LoadingGuard>
      {showModal ? <Loader showModal={showModal} /> : null}
    </>
  );
};
