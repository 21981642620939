import { Grid } from '@material-ui/core';
import { Accommodation, AccommodationType, InclusionStatus, PetAllowance, TripInclusion, UploadedImage } from '@tripr/common';
import React from 'react';
import { Field } from 'react-final-form';
import { FormList } from '../../common/FormList';
import { MySelect } from '../../common/forms/MySelect';
import { MyTextField } from '../../common/forms/TextField';

export const TripInclusionsList: React.FC<{ name: string }> = ({ name }) => (
  <FormList<TripInclusion>
    className="tripInclusion"
    name={name}
    newValue={() => ({ title: '', description: '', status: InclusionStatus.None })}
    render={p => (
      <Grid container spacing={2}>
        <Field component={MyTextField} name={`${p.parentName}.title`} label="Title" xs={8} variant={'filled'} type={'text'} />
        <Field component={MySelect} name={`${p.parentName}.status`} label="Inclusion Status" xs={4} options={InclusionStatus} />
        <Field component={MyTextField} name={`${p.parentName}.description`} label="Description" xs={12} variant={'filled'} multiline />
      </Grid>
    )}
  />
);
